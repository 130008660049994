import {useState} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight, faEnvelope, } from "@fortawesome/free-solid-svg-icons";
import {  faGithub, faInstagram } from "@fortawesome/free-brands-svg-icons";
import hr from '../assets/curve-hr.svg'

import CodeSnap from './CodeSnap';

export default function Hiro () {

    const [loaded, setLoaded] = useState(true);

    const sampleCode = `
let me = {
    name: "Galih Bagus Hardiyanto",
    stack: {
        language: ["Python", "JavaScript", "PHP"],
        backend: ["NodeJS", "Flask"],
        frontend: ["Bootstrap", "ReactJS"],
        others: ["Azure", "Arduino"]
    }
}`;

    return (
        <>
        <div id="home" className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
            <div className='md:w-3/6 md:p-4'>
                <CodeSnap code={sampleCode} language="javascript" />
            </div>
            <div className='md:w-3/6' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="100" >
                <div className="flex flex-col w-full mt-8">
                    <h1 className="text-xl text-gray-400">Hi, I'm</h1>
                    <h1 className="text-2xl font-bold">Galih Bagus Hardiyanto</h1>
                    <p class="text-xl font-bold text-gray-300">FullStack Developer</p>
                    <p className="text-md font-light text-gray-400 ">Enthusiastic and motivated self-learning in Information Technology. Interested in exploring about BackEnd Development, Web Development, and IOT.</p>
                </div>
                <a href='#' className='mt-2 block'>Read My Blog <FontAwesomeIcon className='ml-2' icon={faCircleArrowRight}/> </a>

                <ul className='flex mt-2 gap-4 items-center'>
                   <li>
                        <a href='https://github.com/gbagush' rel="noreferrer" target="_blank"><FontAwesomeIcon size='2xl' icon={faGithub} /></a>
                   </li> 
                    <li>
                        <a href='https://instagram.com/just.gbagus' rel="noreferrer" target="_blank"><FontAwesomeIcon size='2xl' icon={faInstagram} /></a>
                    </li>
                    <li>
                        <a href='mailto:galihbagushardiyanto@gmail.com' rel="noreferrer" target="_blank"><FontAwesomeIcon size='2xl' icon={faEnvelope} /></a>
                    </li>
                </ul>
            </div>
            <img src={hr} className="w-full md:h-2 absolute bottom-0" alt="hr" />
        </div>
        </>
    )
}
